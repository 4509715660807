<template>
  <div class="official-dealers">
    <Map zoom="2" type="dealers"></Map>

    <div class="contacts-container">
      <div class="data-container">
        <h1 data-aos="zoom-in" data-aos-duration="1000" v-html="$t('dealers-title')"></h1>

        <div class="section">
          <div class="dealer" data-aos="zoom-in" data-aos-duration="1000" v-for="item in json" :key="item.name">
            <h3>{{ item.name }}</h3>
            <p v-if="item.address != ''" v-html="item.address"></p>
            <p v-if="item.country != ''">{{ item.country }}</p>
            <p v-if="item.email != ''">
              Email:
              <a :href="'mailto:' + item.email"> {{ item.email }}</a>
            </p>
            <p v-if="item.phone != ''">
              Phone: <a :href="'tel:' + item.phone">{{ item.phone }}</a>
            </p>
            <p v-if="item.fax != ''">Fax: {{ item.fax }}</p>
            <p v-if="item.website != ''"><a :href="item.website">Website</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Map from '../components/Map.vue'
export default {
  name: 'OfficialDealers',
  components: { Map },
  data() {
    return {
      images: [require('../assets/carbo_marker.png'), require('../assets/dealer_marker.png')],
      json: require('../assets/dealers.json')
    }
  },
  methods: {},
  mounted() {
    //LOAD ALL IMAGES IN PAGE
    this.$store.dispatch('LOAD_IMAGES', this.images).then(() => {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$store.commit('setFooterVisible')

          //simulate resize for scrolling transitions
          let event
          if (typeof Event === 'function') {
            event = new Event('resize')
          } else {
            //IE
            event = document.createEvent('Event')
            event.initEvent('resize', true, true)
          }
          window.dispatchEvent(event)
        }, 1100)
      })
    })
  }
}
</script>
<style lang="scss"></style>
